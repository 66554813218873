const languages = require('../../language/languages');

let language = 'en';
let customer = '';
let translations = {};

/**
 * @function setLanguage
 * @param {String} language to use
 * @description Sets the language to use for selection of values from.
 */
const setLanguage = (lang, customer = '') => {
  translationInstance.setLanguage(lang, customer);
};

const translate = (key) => {
  return translations?.[key];
};

class Translation {
  constructor(lang, cust) {
    this.setLanguage(lang, cust);
  }

  setLanguage(lang, cust) {
    language = lang;
    customer = cust;
    this.loadLanguage();
  }

  /**
   * @function loadLanguage
   * @description loads the data for translating into a specific language
   */
  loadLanguage() {
    console.log(`loadLanguage for ${language}:${customer}`);

    const base = languages?.[language]?.base;
    const override = languages?.[language]?.static;
    translations = { ...base, ...override };

    if (customer !== '') {
      const txs = languages?.[language]?.[customer];
      translations = { ...translations, ...txs };
    }
  }

  /**
   * @function getTranslations
   * @description temporary function for testing...
   */
}

const translationInstance = new Translation('en', '');

module.exports = { setLanguage, translate };
