import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Heading, Image } from 'theme-ui';
import PropTypes from 'prop-types';

export default function Header(props) {
  const Router = useRouter();
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_MAINTENANCE_ACTIVE === 'true' &&
      Router.pathname !== '/sorry'
    ) {
      Router.push('/sorry');
    }
  });
  return (
    <React.Fragment>
      <Image
        sx={{ width: '250px', minHeight: '65px' }}
        src="https://ct1.medstarhealth.org/content/uploads/2015/02/msh_logo.png"
        alt="logo"
      />
      <Heading sx={pageSx.heading}>{props.heading}</Heading>
    </React.Fragment>
  );
}

const pageSx = {
  heading: {
    marginTop: '10px',
    fontSize: '24px',
    color: '#002664',
    lineHeight: '29px',
    fontWeight: '700',
    textAlign: 'left',
    paddingBottom: '1rem',
    paddingTop: '2rem',
  },
};

Header.propTypes = {
  heading: PropTypes.string.isRequired,
};
