// BEGIN FORM KEY VALUES
const FIRSTNAME = 'firstname';
const MIDDLENAME = 'middlename';
const LASTNAME = 'lastname';
const SEX_AT_BIRTH = 'sex-at-birth';
const BIRTHDATE = 'dob';
const EMAIL = 'email';
const CELLPHONE = 'cellphone';
const ALTERNATE_PHONE = 'alternate-phone';
const SEND_TEXT = 'send-text';
const SEND_TEXT_PRIMARY = 'send-text-primary';
const SEND_TEXT_SECONDARY = 'send-text-secondary';
const ADDRESS_LINE_1 = 'address-1';
const ADDRESS_LINE_2 = 'address-2';
const ADDRESS_CITY = 'city';
const STATE = 'state';
const ZIPCODE = 'zipcode';
const OCCUPATION = 'occupation';
const OTHER_OCCUPATION = 'other-occupation';
const HAS_HEALTH_CONDITIONS = 'has-health-conditions';
const HEALTH_CONDITIONS = 'health-conditions';
const OTHER_CONDITION = 'other-condition';
const HAS_RECEIVED_DOSE = 'has-received-dose';
const DRUG_COMPANY_USED = 'drug-company-used';
const DOSE_RECEIVED_LOCATION = 'dose-received-location';
const DOSE_RECEIVED_DATE = 'dose-received-date';
const IS_MEDSTAR_PATIENT = 'is-medstar-patient';
const IS_VA_PATIENT = 'is-va-patient';
const IS_HEALTHCARE_WORKER = 'is-healthcare-worker';
const SAME_DAY_VACCINE = 'same-day-vaccine';
const PLACE_ON_CALL_VIA_PHONE = 'place-on-call-via-phone';
const PREFERRED_LOCATION = 'preferred-location';
const BEST_TIME_TO_CALL = 'best-time-to-call';
const EMPI = 'empi';
const IS_SUBMITTED_TRUTHFULLY = 'truthfully-submitted';
const HAS_READ_AND_AGREED = 'read-and-agreed';
const IP_ADDRESS = 'ip-address';
const USER_AGENT = 'user-agent';
const ETHNICITY = 'ethnicity';
const RACE = 'race';
const TWO_OR_MORE_RACES = 'two-or-more-races';
const PRIMARY_PHONE = 'primary-phone';
const PRIMARY_CONTACT_NUMBER = 'primary-contact-number';
const SECONDARY_CONTACT_NUMBER = 'secondary-contact-number';
const PRIMARY_CONTACT_NUMBER_TYPE = 'primary-contact-type';
const SECONDARY_CONTACT_NUMBER_TYPE = 'secondary-contact-type';

const FIRSTNAME_DISPLAY = 'First name';
const MIDDLENAME_DISPLAY = 'Middle name';
const LASTNAME_DISPLAY = 'Last name';
const SEX_AT_BIRTH_DISPLAY = 'Sex assigned at birth';
const BIRTHDATE_DISPLAY = 'Date of birth (MM/DD/YYYY)';
const EMAIL_DISPLAY = 'Email';
const CELLPHONE_DISPLAY = 'Mobile phone #';
const ALTERNATE_PHONE_DISPLAY = 'Home phone #';
const PRIMARY_PHONE_DISPLAY = 'Primary phone';
const SEND_TEXT_DISPLAY =
  'Send me updates via text message (Standard messaging rates may apply)';
const PHONE_TYPE_DISPLAY = 'What type of phone number is this?';
const PRIMARY_CONTACT_NUMBER_DISPLAY = 'Primary contact number';
const SECONDARY_CONTACT_NUMBER_DISPLAY = 'Secondary contact number';
const ADDRESS_LINE_1_DISPLAY = 'Residential Address';
const ADDRESS_LINE_2_DISPLAY = 'Apartment, Suite, Unit, etc. (Optional)';
const ADDRESS_CITY_DISPLAY = 'City';
const STATE_DISPLAY = 'State';
const ZIPCODE_DISPLAY = 'Zip code';
const OTHER_CONDITION_DISPLAY = 'Other';
const DOSE_RECEIVED_DATE_DISPLAY = 'Date of first dose (MM/DD/YYYY)';
const DRUG_COMPANY_USED_DISPLAY = 'Which type did you receive?';
const EMPI_DISPLAY = 'MedStar Health Patient ID (optional)';
const HAS_HEALTH_CONDITIONS_DISPLAY =
  'Do you have any existing health conditions?';
const HAS_RECEIVED_DOSE_DISPLAY =
  'Have you already received the first vaccination?';
const DOSE_RECEIVED_LOCATION_DISPLAY =
  'Was your first vaccine administered by MedStar Health?';
const IS_MEDSTAR_PATIENT_DISPLAY =
  'Have you ever been a patient of MedStar Health?';
const IS_VA_PATIENT_DISPLAY =
  'Are you currently receiving care through the Veterans Administration (VA)?';
const IS_SUBMITTED_TRUTHFULLY_DISPLAY =
  'I have verified this information and confirm that it’s true.';
const HAS_READ_AND_AGREED_DISPLAY =
  'I have received a copy of the Notice of Privacy Policies';
const ETHNICITY_DISPLAY = 'Ethnicity';
const RACE_DISPLAY = 'Race';
const TWO_OR_MORE_RACES_DISPLAY = 'Select all that apply';
const FORM_TITLE_DISPLAY = 'FORM_TITLE_DISPLAY';
const FORM_DEMO_DISPLAY = 'FORM_DEMO_DISPLAY';
const FORM_HEALTH_CONDITIONS_DISPLAY = 'FORM_HEALTH_CONDITIONS_DISPLAY';
const FORM_PERSONAL_SECTION_TITLE_DISPLAY =
  'FORM_PERSONAL_SECTION_TITLE_DISPLAY';
const FORM_REQUIRED_FIELD_TITLE = 'FORM_REQUIRED_FIELD_TITLE';
const FORM_CONTACT_INFORMATION_SECTION_TITLE_DISPLAY =
  'FORM_CONTACT_INFORMATION_SECTION_TITLE_DISPLAY';
const FORM_ADDRESS_SECTION_TITLE_DISPLAY = 'FORM_ADDRESS_SECTION_TITLE_DISPLAY';
const INTRO_TITLE_DISPLAY = 'INTRO_TITLE_DISPLAY';
const INTRO_HEADER_FIRST_DISPLAY = 'INTRO_HEADER_FIRST_DISPLAY';
const INTRO_HEADER_FIRST_BOLD_DISPLAY = 'INTRO_HEADER_FIRST_BOLD_DISPLAY';
const INTRO_P2_DISPLAY = 'INTRO_P2_DISPLAY';
const INTRO_P3_DISPLAY = 'INTRO_P3_DISPLAY';
const INTRO_P3_BOLD_DISPLAY = 'INTRO_P3_BOLD_DISPLAY';
const INTRO_P4_DISPLAY = 'INTRO_P4_DISPLAY';
const INTRO_P5_DISPLAY = 'INTRO_P5_DISPLAY';
const INTRO_P6_DISPLAY = 'INTRO_P6_DISPLAY';
const INTRO_P7_DISPLAY = 'INTRO_P7_DISPLAY';
const INTRO_P8_DISPLAY = 'INTRO_P8_DISPLAY';
const INTRO_P9_DISPLAY = 'INTRO_P9_DISPLAY';
const INTRO_CLICK_HERE_DISPLAY = 'INTRO_CLICK_HERE_DISPLAY';
const INTRO_P10_DISPLAY = 'INTRO_P10_DISPLAY';
const INTRO_PRIVACY_LINK_DISPLAY = 'INTRO_PRIVACY_LINK_DISPLAY';
const INTRO_PRIVACY_PRELINK_DISPLAY = 'INTRO_PRIVACY_PRELINK_DISPLAY';
const INTRO_PRIVACY_POSTLINK_DISPLAY = 'INTRO_PRIVACY_POSTLINK_DISPLAY';
const INTRO_START_BUTTON_DISPLAY = 'INTRO_START_BUTTON_DISPLAY';

const FACILITY_NAME_MS_GHU = 'MedStar Georgetown University Hospital';
const FACILITY_NAME_MS_WHC = 'MedStar Washington Hospital Center';
const FACILITY_NAME_MS_LAFAYETTE = 'MedStar Health at Lafayette Center';
const FACILITY_NAME_MS_NAVY_YARD = 'MedStar Health at Navy Yard';
const FACILITY_NAME_MS_MONTGOMERY = 'MedStar Montgomery Medical Center';
const FACILITY_NAME_MS_FRANKLIN_SQUARE =
  'MedStar Franklin Square Medical Center';
const FACILITY_NAME_MS_BEL_AIR = 'MedStar Health Bel Air Medical Center';
const FACILITY_NAME_MS_GOOD_SAMARITAN = 'MedStar Good Samaritan Hospital';
const FACILITY_NAME_MS_UNION_MEMORIAL = 'MedStar Union Memorial Hospital';
const FACILITY_NAME_MS_HARBOR = 'MedStar Harbor Hospital';
const FACILITY_NAME_MS_ST_MARYS = 'MedStar Southern Maryland Hospital Center';
const RECAPTCHA_KEY = 'recaptcha-key';
const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = /^[A-Za-z][-A-Za-z ]*$/;
const ZIPCODE_REGEX = /^[0-9]{5}$/;
const DATE_REGEX = /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/;
const SITE_EMAIL_SUBJECT = 'Thank you for submitting';
const USER_EMAIL_SUBJECT = 'Thank you for submitting';
const SUPPORTED_LANGUAGES = [
  { key: 'en', title: 'English' },
  { key: 'es', title: 'Spanish' },
];
const DEFAULT_LANGUAGE = { key: 'en', title: 'English' };
const CLIENT_MEDSTAR = 'medstar';

const FORM_KEY_VALUE_NAMES = [
  FIRSTNAME,
  MIDDLENAME,
  LASTNAME,
  SEX_AT_BIRTH,
  BIRTHDATE,
  EMAIL,
  CELLPHONE,
  ALTERNATE_PHONE,
  SEND_TEXT,
  SEND_TEXT_PRIMARY,
  SEND_TEXT_SECONDARY,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  ADDRESS_CITY,
  STATE,
  ZIPCODE,
  OCCUPATION,
  OTHER_OCCUPATION,
  HAS_HEALTH_CONDITIONS,
  HEALTH_CONDITIONS,
  OTHER_CONDITION,
  HAS_RECEIVED_DOSE,
  DRUG_COMPANY_USED,
  DOSE_RECEIVED_LOCATION,
  DOSE_RECEIVED_DATE,
  IS_HEALTHCARE_WORKER,
  IS_MEDSTAR_PATIENT,
  EMPI,
  IS_SUBMITTED_TRUTHFULLY,
  HAS_READ_AND_AGREED,
  RECAPTCHA_KEY,
  PRIMARY_PHONE,
  IS_VA_PATIENT,
  PRIMARY_CONTACT_NUMBER,
  PRIMARY_CONTACT_NUMBER_TYPE,
  SECONDARY_CONTACT_NUMBER,
  SECONDARY_CONTACT_NUMBER_TYPE,
];

// END FORM KEY VALUES

// BEGIN RECAPTCHA
const RECAPTCHA_HUMAN_CODE = 'recaptcha_human_code';
// END RECAPTCHA

//  BEGIN FORM CONTROL VALUES
const EXTERNAL_QUESTIONNAIRE_PAGE_NAME = 'vaccine-form';
const INTERNAL_QUESTIONNAIRE_PAGE_NAME = 'vaccine-form2';
//  END FORM CONTROL VALUES

const FACILITIES_MAP = {
  DC: {
    facilities: [
      {
        name: FACILITY_NAME_MS_NAVY_YARD,
        email: 'MCCOHRegistration@medstar.net',
      },
      {
        name: FACILITY_NAME_MS_LAFAYETTE,
        email: 'MCCOHRegistration@medstar.net',
      },
      {
        name: FACILITY_NAME_MS_WHC,
        email: 'MWHCCentralScheduling@medstar.net',
      },
      {
        name: FACILITY_NAME_MS_GHU,
        email: 'MGUHCS-CC@medstar.net',
      },
    ],
    times: [
      {
        name: '9 a.m. - noon',
      },
      {
        name: 'Noon - 3 p.m.',
      },
      {
        name: '3 p.m. - 5 p.m.',
      },
      {
        name: 'Anytime',
      },
    ],
  },
  MD: {
    facilities: [
      {
        name: FACILITY_NAME_MS_MONTGOMERY,
        email: 'MMMC-COVID-Vaccine@medstar.net',
      },
      {
        name: FACILITY_NAME_MS_FRANKLIN_SQUARE,
        email: 'MFSMC-COVID-Vaccine@medstar.net',
      },
      {
        name: FACILITY_NAME_MS_BEL_AIR,
        email: 'MASBelAir-COVID-Vaccine@medstar.net',
      },
      {
        name: FACILITY_NAME_MS_GOOD_SAMARITAN,
        email: 'GSH-UMHCovidVaccineApptHelp@medstar.net',
      },
      {
        name: FACILITY_NAME_MS_UNION_MEMORIAL,
        email: 'GSH-UMHCovidVaccineApptHelp@medstar.net',
      },
      {
        name: FACILITY_NAME_MS_HARBOR,
        email: 'MHH-COVID-Vaccine@medstar.net',
      },
      {
        name: FACILITY_NAME_MS_ST_MARYS,
        email: 'MSMHCCovidVax@medstar.net',
      },
    ],
    times: [
      {
        name: '9 a.m. - noon',
      },
      {
        name: 'Noon - 3 p.m.',
      },
      {
        name: '3 p.m. - 5 p.m.',
      },
      {
        name: 'Anytime',
      },
    ],
  },
};
const DEFAULT_EMAIL = 'MGUHCS-CC@medstar.net';

module.exports = {
  FIRSTNAME,
  MIDDLENAME,
  LASTNAME,
  SEX_AT_BIRTH,
  BIRTHDATE,
  EMAIL,
  CELLPHONE,
  ALTERNATE_PHONE,
  SEND_TEXT,
  SEND_TEXT_PRIMARY,
  SEND_TEXT_SECONDARY,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  ADDRESS_CITY,
  STATE,
  ZIPCODE,
  OCCUPATION,
  OTHER_OCCUPATION,
  HAS_HEALTH_CONDITIONS,
  HEALTH_CONDITIONS,
  OTHER_CONDITION,
  HAS_RECEIVED_DOSE,
  DRUG_COMPANY_USED,
  SAME_DAY_VACCINE,
  PREFERRED_LOCATION,
  BEST_TIME_TO_CALL,
  DOSE_RECEIVED_LOCATION,
  DOSE_RECEIVED_DATE,
  IS_HEALTHCARE_WORKER,
  IS_MEDSTAR_PATIENT,
  EMPI,
  IS_SUBMITTED_TRUTHFULLY,
  HAS_READ_AND_AGREED,
  FORM_KEY_VALUE_NAMES,
  RECAPTCHA_HUMAN_CODE,
  PLACE_ON_CALL_VIA_PHONE,
  FACILITY_NAME_MS_GHU,
  FACILITY_NAME_MS_LAFAYETTE,
  FACILITY_NAME_MS_NAVY_YARD,
  FACILITY_NAME_MS_WHC,
  FACILITY_NAME_MS_BEL_AIR,
  FACILITY_NAME_MS_FRANKLIN_SQUARE,
  FACILITY_NAME_MS_GOOD_SAMARITAN,
  FACILITY_NAME_MS_MONTGOMERY,
  FACILITY_NAME_MS_HARBOR,
  FACILITY_NAME_MS_UNION_MEMORIAL,
  FACILITY_NAME_MS_ST_MARYS,
  FACILITIES_MAP,
  DEFAULT_EMAIL,
  RECAPTCHA_KEY,
  PHONE_REGEX,
  EMAIL_REGEX,
  NAME_REGEX,
  ZIPCODE_REGEX,
  DATE_REGEX,
  IP_ADDRESS,
  USER_AGENT,
  SITE_EMAIL_SUBJECT,
  USER_EMAIL_SUBJECT,
  FIRSTNAME_DISPLAY,
  MIDDLENAME_DISPLAY,
  LASTNAME_DISPLAY,
  SEX_AT_BIRTH_DISPLAY,
  BIRTHDATE_DISPLAY,
  EMAIL_DISPLAY,
  CELLPHONE_DISPLAY,
  ALTERNATE_PHONE_DISPLAY,
  SEND_TEXT_DISPLAY,
  ADDRESS_LINE_1_DISPLAY,
  ADDRESS_LINE_2_DISPLAY,
  ADDRESS_CITY_DISPLAY,
  STATE_DISPLAY,
  ZIPCODE_DISPLAY,
  OTHER_CONDITION_DISPLAY,
  DOSE_RECEIVED_DATE_DISPLAY,
  DRUG_COMPANY_USED_DISPLAY,
  EMPI_DISPLAY,
  HAS_HEALTH_CONDITIONS_DISPLAY,
  HAS_RECEIVED_DOSE_DISPLAY,
  DOSE_RECEIVED_LOCATION_DISPLAY,
  IS_MEDSTAR_PATIENT_DISPLAY,
  IS_SUBMITTED_TRUTHFULLY_DISPLAY,
  HAS_READ_AND_AGREED_DISPLAY,
  FORM_TITLE_DISPLAY,
  FORM_DEMO_DISPLAY,
  FORM_HEALTH_CONDITIONS_DISPLAY,
  FORM_PERSONAL_SECTION_TITLE_DISPLAY,
  FORM_REQUIRED_FIELD_TITLE,
  FORM_ADDRESS_SECTION_TITLE_DISPLAY,
  FORM_CONTACT_INFORMATION_SECTION_TITLE_DISPLAY,
  INTRO_TITLE_DISPLAY,
  INTRO_HEADER_FIRST_BOLD_DISPLAY,
  INTRO_HEADER_FIRST_DISPLAY,
  INTRO_P2_DISPLAY,
  INTRO_P3_DISPLAY,
  INTRO_P3_BOLD_DISPLAY,
  INTRO_P4_DISPLAY,
  INTRO_P5_DISPLAY,
  INTRO_P6_DISPLAY,
  INTRO_P7_DISPLAY,
  INTRO_P8_DISPLAY,
  INTRO_P9_DISPLAY,
  INTRO_CLICK_HERE_DISPLAY,
  INTRO_P10_DISPLAY,
  INTRO_PRIVACY_PRELINK_DISPLAY,
  INTRO_PRIVACY_LINK_DISPLAY,
  INTRO_PRIVACY_POSTLINK_DISPLAY,
  INTRO_START_BUTTON_DISPLAY,
  ETHNICITY,
  ETHNICITY_DISPLAY,
  RACE,
  RACE_DISPLAY,
  TWO_OR_MORE_RACES,
  TWO_OR_MORE_RACES_DISPLAY,
  PRIMARY_PHONE,
  PRIMARY_PHONE_DISPLAY,
  IS_VA_PATIENT,
  IS_VA_PATIENT_DISPLAY,
  SUPPORTED_LANGUAGES,
  DEFAULT_LANGUAGE,
  PHONE_TYPE_DISPLAY,
  PRIMARY_CONTACT_NUMBER,
  PRIMARY_CONTACT_NUMBER_DISPLAY,
  PRIMARY_CONTACT_NUMBER_TYPE,
  SECONDARY_CONTACT_NUMBER,
  SECONDARY_CONTACT_NUMBER_DISPLAY,
  SECONDARY_CONTACT_NUMBER_TYPE,
  CLIENT_MEDSTAR,
  EXTERNAL_QUESTIONNAIRE_PAGE_NAME,
  INTERNAL_QUESTIONNAIRE_PAGE_NAME,
};
