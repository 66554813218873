const baseEs = require('./constants_es.json');
const staticEs = require('./constants_statics_es.json');
const medstarEs = require('./constants_medstar_es.json');
const baseEn = require('./constants_en.json');

const languages = {
  es: {
    base: baseEs,
    static: staticEs,
    medstar: medstarEs,
  },
  en: {
    base: baseEn,
  },
};

module.exports = languages;
